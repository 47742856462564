import inobounce from 'inobounce';

export const InbounceMixin = {
  data() {
    return {}
  },
  mounted() {
    let userAgent = navigator.userAgent
    if (userAgent.indexOf('iPhone') > -1) {
      inobounce.enable()
    }
  },
  beforeDestroy() {
    inobounce.disable()
  },
}
