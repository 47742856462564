<template>
  <div class="task-info">
    <div class="content">
      <card title="任务基本信息">
        <div class="info-item space-between">
          <div class="label">任务编号</div>
          <div class="value">{{ taskInfo.taskNo }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">任务发布人</div>
          <div class="value">{{ taskInfo.publisher }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">任务发布时间</div>
          <div class="value">{{ taskInfo.publisherTime }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">验货类型</div>
          <div class="value">{{ taskInfo.inspectionType_dictText }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">验货员</div>
          <div class="value">{{ taskInfo.inspector }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">验货员电话</div>
          <div class="value highlight" @click="handleCall(taskInfo.inspectorPhone)">
            <span>{{ taskInfo.inspectorPhone }}</span>
            <img class="icon-phone" src="~@/assets/svg/icon-phone.svg" alt="">
          </div>
        </div>
        <div class="info-item space-between">
          <div class="label">
            {{ taskInfo.status === taskStatus.TASK_STATUS_IN_DISPATCH ? '可验货日期' : '预定验货日期' }}
          </div>
          <div class="value">
            <div class="available-date">
              <div class="date">
                <template v-if="taskInfo.status === taskStatus.TASK_STATUS_IN_DISPATCH">
                  <div v-for="(item, index) in dateList" :key="index">
                    <span>{{ item.startDate }}  至  {{ item.endDate }}</span>
                  </div>
                </template>
                <span v-else>{{ taskInfo.inspectDate || ''}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="info-item space-between">
          <div class="label">验货任务备注</div>
          <div class="value">
            {{ taskInfo.remarks }}
          </div>
        </div>
      </card>
      <!--<card title="验货类型">
        <div class="info-item space-between">
          <div class="label">验货类型</div>
          <div class="value">{{ taskInfo.inspectionType_dictText }}</div>
        </div>
      </card>
      <card title="可验货日期">
        <div class="available-date">
          <div class="date">
            <div v-for="(item, index) in dateList" :key="index">
              <span>{{ item.startDate }}  至  {{ item.endDate }}</span>
            </div>
          </div>
        </div>
      </card>-->
      <card title="供应商信息">
        <div class="info-item space-between">
          <div class="label">供应商</div>
          <div class="value">{{ taskInfo.supplierName }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">验货工厂</div>
          <div class="value">{{ taskInfo.factoryName }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">工厂地址</div>
          <div class="value highlight" @click="handleAddr">
            {{ taskInfo.factoryAddr }}
            <img class="icon-location" src="~@/assets/img/icon_location.png" alt="">
          </div>
        </div>
        <div class="info-item space-between">
          <div class="label">详细地址</div>
          <div class="value">{{ taskInfo.factoryAddrDetail }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">工厂联系人</div>
          <div class="value">{{ taskInfo.factoryContact }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">工厂联系电话</div>
          <div class="value highlight" @click="handleCall(factoryPhoneNumber)">
            <span>{{ factoryPhoneNumber }}</span>
            <img class="icon-phone" src="~@/assets/svg/icon-phone.svg" alt="">
          </div>
        </div>
      </card>
      <!--      <card title="验货任务备注">-->
      <!--        <div class="info-item space-between">-->
      <!--          <div class="label">验货任务备注</div>-->
      <!--          <div class="value">-->
      <!--            {{ taskInfo.remarks }}-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </card>-->
    </div>
    <van-calendar
      v-model="isShowCalendar"
      :min-date="minCalendarDate"
      title="请选择具体的验货日期"
      :formatter="calendarFormatter"
      color="#055C9D"
      :round="false"
      :default-date="null"
      class="calendar"
      @select="onCalendarSelect"
    >
      <div class="calendar-footer" slot="footer">
        <van-button
          type="primary"
          color="#055C9D"
          class="btn"
          @click="onCalendarConfirm"
        >
          确定
        </van-button>
        <div class="red-date-tip">* 标红日期已有验货任务</div>
      </div>
    </van-calendar>
    <template>
      <div v-if="isShowTaskAcceptRejectBtn" class="fixed-two-btn">
        <van-button
          :loading="cancelLoading"
          :disabled="cancelLoading"
          size="large"
          loading-text="拒绝中"
          class="btn"
          color="#055C9D"
          plain
          @click="handleReject"
        >
          拒绝
        </van-button>
        <van-button
          :loading="confirmLoading"
          :disabled="confirmLoading"
          size="large"
          loading-text="接单中"
          class="btn"
          color="#055C9D"
          type="primary"
          @click="handleAccept"
        >
          接单
        </van-button>
      </div>
      <div v-if="isShowCancelBtn" class="fixed-two-btn">
        <van-button
          v-if="isShowCancelBtn"
          :loading="cancelLoading"
          :disabled="cancelLoading"
          size="large"
          loading-text="取消中"
          class="btn"
          color="#055C9D"
          plain
          @click="handleCancel"
        >
          取消任务
        </van-button>
<!--        <van-button-->
<!--          v-if="isShowDetailBtn"-->
<!--          class="btn"-->
<!--          size="large"-->
<!--          color="#055C9D"-->
<!--          type="primary"-->
<!--          @click="handleDetail"-->
<!--        >-->
<!--          查看订单-->
<!--        </van-button>-->
      </div>
    </template>
  </div>
</template>

<script>
import {TASK_STATUS_IN_DISPATCH, taskStatusObj} from '@/constants';
import Card from '@/components/Common/Card.vue'
import {getAction} from '@/api/manage';
import moment from 'moment';
import {InbounceMixin} from '@/mixins/InbounceMixin';
import {mapActions, mapGetters, mapMutations} from "vuex";
import {postAction} from "@/api/manage";
import {isNative} from "@/config";
import {getDocumentTitle, getArticleNoLength} from "@/utils/taskData";

export default {
  name: "taskInfo",
  mixins: [InbounceMixin],
  components: {
    Card
  },
  data() {
    return {
      taskId: '',
      cancelLoading: false,
      confirmLoading: false,
      taskStatus: taskStatusObj,
      isShowCalendar: false,
      // minCalendarDate: new Date(),
      isWeakNet: false,
      inspectDateList: [],
      checkedDate: null,
      teamId: null,
      code: 0,
    }
  },
  computed: {
    ...mapGetters(['taskInfo']),
    taskInfoTitle() {
      const num = getArticleNoLength(this.taskInfo)
      const nameList = [this.taskInfo.factoryName || '', this.taskInfo.inspectionType_dictText || '', `${num}款`]
      return nameList.join('-')
    },
    factoryPhoneNumber() {
      if (this.taskInfo.factoryPhone) {
        return this.taskInfo.factoryPhone
      } else return this.taskInfo.factoryTel
    },
    isShowTaskAcceptRejectBtn() {
      const taskStatusOk = this.taskInfo.status === taskStatusObj.TASK_STATUS_IN_DISPATCH
      return taskStatusOk && !this.isWeakNet
    },
    isShowCancelBtn() {
      const taskStatusOk = [taskStatusObj.TASK_STATUS_WAIT_INSPECT].includes(this.taskInfo.status)
      return taskStatusOk && !this.isWeakNet
    },
    isShowDetailBtn() {
      return this.taskInfo.status !== taskStatusObj.TASK_STATUS_IN_DISPATCH
    },
    dateList() {
      const date = this.taskInfo?.inspectionValidDate || ''
      const dateRangeList = date.split(',')
      return dateRangeList.map(item => {
        const dateList = item.split('-')
        return {
          startDate: dateList?.length === 2 ? moment(dateList[0]).format('YYYY-MM-DD') : '-',
          endDate: dateList?.length === 2 ? moment(dateList[1]).format('YYYY-MM-DD') : '-',
        }
      })
    },
    minCalendarDate() {
      return new Date()
      // if (this.startDate) {
      //   return new Date(this.startDate)
      // } else return new Date()
    },
  },
  created() {
    console.log('taskInfo page created, the url is:', window.location.href)
    this.init()
  },
  mounted() {
  },
  methods: {
    ...mapMutations(['SET_TASK_ID']),
    ...mapActions(['getTaskInfo', 'saveTaskInfo']),
    async init() {
      console.log('init.....')
      this.taskId = this.$route.query.taskId
      this.SET_TASK_ID(this.taskId)
      this.Toast.loading({message: '加载中'})
      let res = {}
      try {
        res = await this.getTaskInfo(this.taskId)
        this.code = res?.code
      } catch (e) {
        console.log('获取任务信息失败：')
        console.error(e)
      }
      // if(res?.code === -5) {   // 已拒绝的任务
      //   if(isNative) {
      //     setTimeout(()=>{
      //       window.nativeApp.backPressed()
      //     }, 500)
      //   }
      //   return
      // }
      this.Toast.clear()
      document.title = getDocumentTitle(this.taskInfo)
      // if (isNative) {
      //   try {
      //     const res = await window.nativeApp.isWeakNet()
      //     this.isWeakNet = res.result === 'true'
      //     console.log('after await window.nativeApp.isWeakNet(), this.isWeakNet value:', this.isWeakNet, res)
      //   } catch (e) {
      //     console.log('获取window.nativeApp.isWeakNet失败：')
      //     console.error(e)
      //   }
      // }
      console.log('this.taskInfo.status :', this.taskInfo.status)
      if (!this.isWeakNet && this.taskInfo.status === taskStatusObj.TASK_STATUS_IN_DISPATCH) {
        this.checkedDate = null
        this.getTaskInspectDate()
      }
    },
    calendarFormatter(day) {
      if (this.inspectDateList.includes(moment(new Date(day.date)).format('YYYY-MM-DD'))) {
        day.className = 'red-day'
      }
      let valid = this.isDayValid(day.date)
      if (!valid) {
        day.className = 'van-calendar__day--disabled'  // 不在验货范围内的有任务日期会被覆盖展示为disable状态;(
        // day.className += ' van-calendar__day--disabled'  // 不在验货范围内的有任务日期还是展示为红色
      }
      return day
    },
    isDayValid(date) {
      let valid = false
      this.dateList.forEach(range => {
        const isValid = moment(date.getTime()).isBetween(range.startDate, range.endDate, null, '[]')
        if (isValid) {
          valid = true
        }
      })
      return valid
    },
    handleCall(phone) {
      window.location.href = `tel:${phone}`
    },
    handleAddr() {
      if (this.taskInfo.factoryLatitude && this.taskInfo.factoryLongitude) {
        window.nativeApp.mapNavigation(`${this.taskInfo.factoryLatitude}, ${this.taskInfo.factoryLongitude}`, this.taskInfo.factoryAddr);
      }
    },
    handleReject() {
      this.$dialog.confirm({
        message: '您真的要拒绝接单吗？',
        confirmButtonText: '狠心拒绝',
        confirmButtonColor: '#F94837',
        cancelButtonText: '点错了',
        closeOnClickOverlay: true,
        className: "vant-dialog-confirm-red"
      }).then(async () => {
        this.cancelLoading = true
        const res = await postAction(`/jeecg-system/aqyh/app/task/rejectTask?id=${this.taskId}`)
        this.cancelLoading = false
        if (res.success) {
          if (isNative) {
            try {
              await window.nativeApp.taskReject(this.taskId)
              window.nativeApp.backPressed()
            } catch (e) {
              console.log('调用原生返回出错:')
              console.error(e)
            }
          } else {
            this.$router.back()
          }
        } else this.$toast.fail(res.message)
      })
    },
    handleAccept() {
      let valid = false
      if (this.dateList.length) {
        this.dateList.forEach(range => {
          if (moment(new Date()).isBefore(range.startDate)
            || moment(new Date()).isBefore(range.endDate)
            || moment(new Date()).isSame(range.startDate, 'day')
            || moment(new Date()).isSame(range.endDate, 'day')
          ) {
            valid = true
          }
        })
      }
      if (valid) {
        this.isShowCalendar = true
      } else {
        this.$toast.fail("当前日期已超过可验货日期，请选择拒单退回该验货任务")
      }
    },
    onCalendarSelect(val) {
      if (val && this.isDayValid(val)) {
        this.checkedDate = moment(val).format('YYYY-MM-DD')
      } else {
        this.checkedDate = null
      }
    },
    async onCalendarConfirm(val) {
      if (!this.checkedDate) {
        this.$toast.fail("请选择接单日期")
        return
      }
      this.isShowCalendar = false
      this.confirmLoading = true
      const res = await postAction(`/jeecg-system/aqyh/app/task/acceptTask?id=${this.taskId}&inspectDate=${this.checkedDate}`, {})
      if (res.success) {
        if (isNative) {
          try {
            await window.nativeApp.taskAccept(this.taskId)
          } catch (e) {
            console.log('调用原生返回出错:')
            console.error(e)
          }
        }
        console.log('before handleDetail....')
        this.confirmLoading = false
        this.handleDetail()
        // this.init()
      } else this.$toast.fail(res.message)
    },
    handleCancel() {
      this.$dialog.confirm({
        message: '您真的要取消验货吗？',
        confirmButtonText: '确定',
        confirmButtonColor: '#F94837',
        cancelButtonText: '点错了',
        closeOnClickOverlay: true,
        className: "vant-dialog-confirm-red"
      }).then(async () => {
        this.cancelLoading = true
        const res = await postAction(`/jeecg-system/aqyh/app/task/cancelTask?id=${this.taskId}`,)
        this.cancelLoading = false
        if (res.success) {
          if (isNative) {
            try {
              // window.nativeApp.backPressed()
              window.nativeApp.finish()
            } catch (e) {
              console.log('调用原生返回出错:')
              console.error(e)
            }
          } else {
            this.$router.back()
          }
        } else this.$toast.fail(res.message)
      })
    },
    handleDetail() {
      console.log('this.code:', this.code)
      if (this.code === -5) {
        this.$toast('该验货任务已被拒绝，无法查看！')
        return
      }
      console.log('router start push...')
      this.$router.push({
        path: '/orderList',
        query: {
          ...this.$route.query,
        },
      })
      console.log('router push success')
    },
    async getTaskInspectDate() {
      let teamId = this.$route.query.teamId
      if (isNative) {
        try {
          const res = await window.nativeApp.getUserInfo()
          if (res.success) {
            const result = JSON.parse(res.result)
            teamId = result.teamId
          }
        } catch (e) {
          console.log('获取用户信息出错：')
          console.error(e)
        }
      }
      const res = await getAction('/jeecg-system/aqyh/app/task/taskInspectDate', {
        teamId,
      })
      if (res.success) {
        this.inspectDateList = res.result
      }
    },
  }
}
</script>

<style lang="less" scoped>
.task-info {
  padding: 1px 16px 16px 16px;
  background: #F9F9F9;
  height: calc(100vh - 16px);
  overflow-y: auto;

  .content {
    padding-bottom: 86px;
  }

  .calendar {
    border-radius: 20px 20px 0 0;

    ::v-deep {
      .van-calendar__header-title {
        position: relative;
      }

      .van-calendar__day--disabled {
        .van-calendar__selected-day {
          background: none !important;
          color: #c8c9cc;
        }
      }

      .van-calendar__confirm {
        border-radius: 4px;
      }

      .red-day {
        background: #F94837;
        z-index: 9;
        height: 54px;
        margin-top: 5px;
        border-radius: 4px;
        color: #fff;
      }
    }

    .calendar-footer {
      width: 100%;
      height: 70px;
      background: #fff;
      padding: 5px 0;

      .btn {
        width: 100%;
        height: 36px;
      }

      .red-date-tip {
        font-size: 12px;
        color: #F94837;
        text-align: center;
        margin: 12px 0;
      }
    }
  }


  .highlight {
    color: #055C9D !important;
  }

  .info-item {
    line-height: 20px;
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .label {
      width: 80px;
      font-size: 12px;
      color: #AAB3BA;
    }

    .value {
      width: calc(100% - 80px);
      font-size: 14px;
      word-break: break-all;
      color: #4B4E51;
    }
  }

  .icon-phone {
    width: 12px;
    height: 12px;
    margin-left: 10px;
  }

  .icon-location {
    width: 8px;
    height: 10px;
  }

  .available-date {
    .date {
      font-size: 14px;
      color: #2F3133;
    }
  }
}
</style>
<style lang="less">
.vant-dialog-confirm-red {
  .van-dialog__message {
    font-weight: 600;
    color: #000000e6;
  }
}
</style>
